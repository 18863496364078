import React, { useId } from 'react';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { CalendarIcon } from '@acadeum/icons';

import { normalizeInboundDate, normalizeOutboundDate } from '@acadeum/helpers';

import styles from './DateRangePicker.module.scss';

type DateRangeValue = [number | Date | undefined | null, number | Date | undefined | null];

export interface DateRangePickerProps {
  id?: string;
  label?: string;
  name?: string;
  readOnly?: boolean;
  required?: boolean;
  utc: boolean;
  minDate?: Date | number;
  value?: DateRangeValue;
  onChange: (values: DateRangeValue) => void;
  isFilter?: boolean;
  appearance?: 'white';
  style?: React.CSSProperties;
  showMonthYearPicker?: boolean;
}

export const DateRangePicker = ({
  id,
  label,
  name,
  readOnly,
  required,
  utc = true,
  minDate,
  value,
  onChange: onChange_,
  isFilter,
  appearance,
  style,
  showMonthYearPicker
}: DateRangePickerProps) => {
  const [startDate, endDate] = Array.isArray(value) ? value : [null, null];
  const labelId = useId();

  const onChange = (range) => {
    const [start, end] = range;
    onChange_([normalizeOutboundDate(start, { utc }), normalizeOutboundDate(end, { utc })]);
  };

  return (
    <div className={styles.DateRangePicker} style={style}>
      {isFilter && (
        // It looks like "react-datepicker" doesn't support "aria-label" property
        // but supports "aria-labelledby" property so this label tag is added
        // for the correct screen readers' work with this field when DateRangePicker
        // is used as filter control
        // https://github.com/Hacker0x01/react-datepicker/issues/1684
        // https://github.com/Hacker0x01/react-datepicker/issues/3427
        <label id={labelId} htmlFor={id}>{label}</label>
      )}
      <ReactDatePicker
        className={classNames(styles.input, {
          [styles.white]: isFilter || appearance === 'white'
        })}
        selectsRange
        dateFormat={showMonthYearPicker ? 'MM/yyyy' : undefined}
        showMonthYearPicker={showMonthYearPicker}
        disabled={readOnly}
        id={id}
        name={name}
        minDate={normalizeInboundDate(minDate, { utc })}
        startDate={normalizeInboundDate(startDate, { utc })}
        endDate={normalizeInboundDate(endDate, { utc })}
        placeholderText={showMonthYearPicker ? 'MM/YYYY - MM/YYYY' : '"MM/DD/YYYY - MM/DD/YYYY"'}
        required={required}
        onChange={onChange}
      />
      <span className={styles.iconWrapper}>
        <CalendarIcon className="control-icon" />
      </span>
    </div>
  );
};

DateRangePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  utc: PropTypes.bool,
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]),
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.instanceOf(Date)
    ])
  ),
  onChange: PropTypes.func.isRequired,
  isFilter: PropTypes.bool,
  appearance: PropTypes.oneOf(['white']),
  style: PropTypes.object
} as NonNullable<unknown>;
