import type { Id, SearchInstitutionType } from '@acadeum/types';
import { getAlgoliaIndex } from './algolia';

function getInstitutionsIndex() {
  return getAlgoliaIndex('institutions');
}

export async function getMemberInstitutionOptionsByName(name, parameters) {
  return getMemberInstitutionOptionsByName_(name, parameters);
}

export async function getMemberInstitutionOptionByValue(id, parameters) {
  return getMemberInstitutionOptionByValue_(id, parameters);
}

export async function getMemberInstitutionById(id) {
  return getMemberInstitutionById_(id);
}

/**
 * Returns a list of `<Autocomplete/>` options by institution name.
 * @param  {string} name
 * @param {Object} where
 * @return {object[]} institutionOptions — Objects with shape `{ value, label }`.
 */

interface InstitutionOptionsByNameOptions {
  type: SearchInstitutionType,
  limit?: number,
  variant?: 'valueAndLabel' | 'valueAndLabelAndLogoUrl'
}

export async function getMemberInstitutionOptionsByName_(name, {
  type,
  limit = 10,
  variant = 'valueAndLabel'
}: InstitutionOptionsByNameOptions) {
  const where = getMemberInstitutionWhere({ type });

  const institutions = await getInstitutionsIndex().findAll({
    query: name,
    limit,
    where
  });

  return institutions.map(getOptionObjectCreatorFunction(variant));
}

/**
 * Returns an `<Autocomplete/>` option by institution's ID.
 * @param  {number} id
 * @param variant - 'valueAndLabel' | 'valueAndLabelAndLogoUrl'
 * @return {object} [institutionOption] — Object with shape `{ value, label }`.
 */
export async function getMemberInstitutionOptionByValue_(id, {
  variant = 'valueAndLabel'
}: {
  variant?: 'valueAndLabel' | 'valueAndLabelAndLogoUrl'
}) {
  const institution = await getMemberInstitutionById(id);
  if (institution) {
    return getOptionObjectCreatorFunction(variant)(institution);
  }
}

function getOptionForMemberInstitutionWithValueAndLabel(memberInstitution): {
  value: Id,
  label: string
} {
  return {
    value: memberInstitution.id,
    label: memberInstitution.name
  };
}

function getOptionForMemberInstitutionWithValueAndLabelAndLogoUrl(memberInstitution): {
  value: Id,
  label: string,
  logoUrl: string
} {
  return {
    value: memberInstitution.id,
    label: memberInstitution.name,
    logoUrl: memberInstitution.logoUrl
  };
}

/**
 * Searches institution by its ID in Algolia.
 * @param  {number} id
 * @param getInstitutionsIndex
 * @return {object} [institution]
 */
export async function getMemberInstitutionById_(id) {
  return await getInstitutionsIndex().get(String(id));
}

interface MemberInstitutionWhere {
  teaching?: boolean;
  stripeSender?: boolean;
  stripeRecipient?: boolean;
}

function getMemberInstitutionWhere(options: { type: SearchInstitutionType }) {
  const { type } = options;
  const where: MemberInstitutionWhere = {};

  switch (type) {
    case 'homeInstitution':
      where.teaching = false;
      break;
    case 'teachingInstitution':
      where.teaching = true;
      break;
    case 'stripeSenderInstitution':
      where.stripeSender = true;
      break;
    case 'stripeRecipientInstitution':
      where.stripeRecipient = true;
      break;
  }

  return where;
}

function getOptionObjectCreatorFunction(variant: 'valueAndLabel' | 'valueAndLabelAndLogoUrl') {
  switch (variant) {
    case 'valueAndLabel':
      return getOptionForMemberInstitutionWithValueAndLabel;
    case 'valueAndLabelAndLogoUrl':
      return getOptionForMemberInstitutionWithValueAndLabelAndLogoUrl;
  }
}
