/* eslint-disable */
import { arrayOf, bool, instanceOf, number, objectOf, oneOf, oneOfType, shape, string } from 'prop-types';

const id = oneOfType([
  string,
  number
]);

const date = instanceOf(Date);

const contact = shape({
  email: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  phone: string,
  title: string
});

const video = shape({
  id: string.isRequired,
  provider: oneOf(['YouTube', 'Vimeo']).isRequired,
  aspectRatioWidth: number,
  aspectRatioHeight: number
});

const accreditations = arrayOf(shape({
  link: string,
  name: string.isRequired
}));

const prerequisite = shape({
  id: id.isRequired,
  code: string,
  course: shape({
    id: id.isRequired,
    code: string.isRequired,
    title: string.isRequired
  })
  // substitutes: arrayOf(shape({
  //   institutionId: id.isRequired,
  //   code: string.isRequired,
  //   title: string.isRequired
  // })).isRequired
});

const prerequisites = arrayOf(prerequisite);

const institutionGradingScale = arrayOf(shape({
  grade: string.isRequired,
  description: string,
  // `numeric` can be a range, not just a number.
  // Examples: `70-79`, `< 60.00%`, '59.9% or below', `Below 60`, `<60`, `0 to 59`, `93% - 100%`, `93+`, `Less than 60`, `Withdrew from an non credit course`.
  // Therefore it's a `string`.
  // Alternatively it could be just a `from` number for each step (exluding the first one).
  // https://github.com/oseibonsu/CollegeConsortiumTickets/issues/127#issuecomment-585398752
  numeric: string,
  points: number
}));

const consortium = shape({
  id: id.isRequired,
  name: string.isRequired,
  shortName: string,
  vanityUrl: string,
  logoUrl: string.isRequired,
  logoRasterUrl: string,
  website: string.isRequired,
  isActive: bool.isRequired,
  institutions: arrayOf(shape({
    id: id.isRequired,
    name: string.isRequired
  })).isRequired
});

const algoliaConsortium = shape({
  id: id.isRequired,
  name: string.isRequired,
  shortName: string,
  vanityUrl: string,
  logo: string.isRequired
});

const pricingStringFilter = oneOfType([
  string,
  arrayOf(string)
]);

const pricingNumericFilter = oneOfType([
  number,
  arrayOf(number),
  shape({
    '>': number,
    '<': number,
    '>=': number,
    '<=': number
  })
]);

const institutionData = shape({
  accreditations,
  membership: arrayOf(shape({
    link: string,
    name: string.isRequired
  })),
  partnership: arrayOf(shape({
    link: string,
    name: string.isRequired
  })),
  affiliations: oneOfType([string, arrayOf(string)]),
  academicCalendarUrl: string,
  academicCalendar: shape({
    terms: arrayOf(shape({
      name: string.isRequired,
      sessions: arrayOf(shape({
        name: string,
        startDate: string.isRequired,
        endDate: string.isRequired,
        addDropEnds: string
      }))
    }))
  }),
  gradingScale: institutionGradingScale,
  gradingScaleUrl: string,
  gradingScaleNotes: string,
  // "Levels offered".
  levels: string
});

const institutionShape = {
  id: id.isRequired,
  logo: string,
  // `logoUrl` is not currently used.
  // `logo` property will be renamed to `logoUrl` in some future.
  logoUrl: string,
  logoRasterUrl: string,
  name: string.isRequired,
  fullName: string,
  shortName: string,
  vanityUrl: string,
  city: string,
  state: string,
  country: string
};

const coursePricing = arrayOf(shape({
  model: oneOf([
    'per-credit',
    'percent-fee',
    'fixed',
    'fixed-fee',
    'as-is'
  ]).isRequired,
  value: number,
  level: pricingStringFilter,
  teachingInstitutionId: pricingNumericFilter,
  consortiumId: number
}));

const institutionSQLShape = {
  ...institutionShape,
  isTeaching: bool,
  version: number.isRequired,
  description: string,
  url: string,
  addressLine1: string,
  addressLine2: string,
  postalCode: string,
  learningManagementSystem: string,
  studentInformationSystem: string,
  muteStudentEmails: bool,
  regionallyAccredited: bool,
  invoiceAutoAdvancement: bool,
  paymentMethod: oneOf(['ACH', 'CARD']).isRequired,
  acceptedPaymentMethod: oneOf(['ACH', 'CARD']).isRequired,
  stripeAccountType: oneOf(['STANDARD', 'CUSTOM']).isRequired,
  // "Level" (two-year, four-year).
  level: string.isRequired,
  funding: oneOf([
    'Public, Not-For-Profit',
    'Private, Not-For-Profit',
    'Private, For-Profit'
  ]).isRequired,
  historicallyBlackCollegesAndUniversities: bool,
  ipedsId: number,
  teachingStudentWelcomeLetter: string,
  teachingStudentWelcomeLetterForMarketplace: string,
  transcriptRequestUrl: string,
  webhooks: shape({
    accessToken: string.isRequired,
    enrollments: string,
    enrollmentsByStatus: shape({
      REQUESTED: string,
      REMOVED: string,
      DENIED: string,
      PENDING: string,
      WITHDRAWN: string,
      ACTIVE: string,
      DROPPED: string,
      DUE: string,
      COMPLETE: string
    })
  }),
  sftp: shape({
    path: string.isRequired,
    exportCourses: bool,
    exportDegrees: bool
  }),
  stripeCustomerId: string.isRequired,
  stripeDestinationId: string,
  emailDomains: arrayOf(string).isRequired,
  consortialEnrollmentHomeInstitutionIds: arrayOf(id).isRequired,
  systemAdministratorContact: contact
};

const institution = shape({
  ...institutionSQLShape,
  data: institutionData.isRequired
});

const institutionNoData = shape({
  ...institutionSQLShape
});

const institutionStringData = shape({
  ...institutionSQLShape,
  data: string.isRequired
});

const institutionAlgolia = shape({
  ...institutionShape,
  teaching: bool,
  // In Algolia `section.session.course.institution`
  // `accreditations` is an array of strings.
  // In "Institution Search" though it's an array of object for legacy reasons.
  // Someone migrate Algolia Institutions' `accreditations` to an array of strings.
  accreditations: oneOfType([arrayOf(string), accreditations]).isRequired,
  affiliations: oneOfType([string, arrayOf(string)]),
  learningManagementSystem: string,
  control: string,
  consortialEnrollingInstitutionIds: arrayOf(id),
  regionallyAccredited: bool
});

const courseShape = {
  id: id.isRequired,
  institution: institutionNoData.isRequired,
  categories: arrayOf(string).isRequired,
  code: string.isRequired,
  title: string,
  description: string,
  hours: number,
  level: string,
  requiredTexts: string
};

const course = shape({
  prerequisiteText: string,
  ...courseShape
});

const courseSchedule = arrayOf(shape({
  term: string.isRequired,
  sessions: arrayOf(string)
}));

const courseAlgoliaShape = {
  ...courseShape,
  prerequisitesText: string,
  hasPrerequisites: bool,
  categories: arrayOf(string).isRequired,
  institution: institutionAlgolia.isRequired,
  hasFacultyCredentials: bool.isRequired,
  hasLearningAssessments: bool.isRequired,
  // `approvedBy` is present both in `course` objects
  // and in `section.session.course` sub-objects.
  approvedBy: arrayOf(number).isRequired,
  customSchedules: objectOf(courseSchedule).isRequired,
  facultyCredentials: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    fileType: oneOf(['pdf']),
    url: string.isRequired
  })).isRequired,
  learningAssessments: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    fileType: oneOf(['pdf']),
    url: string.isRequired
  })).isRequired,
  syllabusUrl: string
};

const courseAlgolia = shape({
  ...courseAlgoliaShape,
  prerequisites,
  sessions: arrayOf(shape({
    id: id.isRequired,
    term: string.isRequired,
    name: string.isRequired,
    startDate: number.isRequired,
    endDate: number.isRequired,
    lastAddDate: number.isRequired,
    lastDropDate: number.isRequired,
    cost: number.isRequired,
    // scheduledBy: arrayOf(id).isRequired,
    sections: arrayOf(shape({
      id: id.isRequired,
      number: string.isRequired,
      schedule: string
    })).isRequired
  })).isRequired
});

const sessionCourseAlgolia = shape({
  ...courseAlgoliaShape,
  sessions: arrayOf(shape({
    id: id.isRequired,
    term: string.isRequired,
    name: string.isRequired,
    // These dates are `Date`s when fetched via Redux actions
    // and `number`s when returned as an Algolia Instant Search result card.
    startDate: oneOfType([date, number]).isRequired,
    endDate: oneOfType([date, number]).isRequired,
    lastAddDate: oneOfType([date, number]).isRequired,
    // lastDropDate: oneOfType([date, number]).isRequired,
    sections: arrayOf(shape({
      id: id.isRequired
      // number: string.isRequired
    })).isRequired
  })).isRequired
});

const sessionShape = {
  id: id.isRequired,
  term: string.isRequired,
  name: string.isRequired,
  course: course.isRequired,
  cost: number.isRequired
};

const session = shape({
  ...sessionShape
});

const sessionAlgolia = shape({
  ...sessionShape,
  // scheduledBy: arrayOf(id).isRequired,
  cost: number, // `session.cost` is stripped in `public-`-prefixed Algolia indexes.
  course: sessionCourseAlgolia.isRequired
});

const sectionShape = {
  id: id.isRequired,
  number: string.isRequired,
  // isActive: bool.isRequired,
  session: session.isRequired
};

const section = shape({
  ...sectionShape,
  instructorFirstName: string,
  instructorMiddleName: string,
  instructorLastName: string,
  schedule: string
});

const sectionAlgolia = shape({
  ...sectionShape,
  session: sessionAlgolia.isRequired
});

const studentShape = {
  id: id.isRequired,
  institution: institutionNoData.isRequired,
  hiStudentId: string.isRequired,
  firstName: string.isRequired,
  middleName: string,
  lastName: string.isRequired,
  phone: string,
  email: string.isRequired,
  level: string,
  gender: bool,
  citizenship: bool,
  residency: string,
  advisorName: string,
  advisorEmail: string,
  addressLine1: string.isRequired,
  addressLine2: string,
  city: string.isRequired,
  state: string.isRequired,
  country: string,
  postalCode: string.isRequired,
  major: string,
  notes: string
};

const student = shape({
  ...studentShape,
  dob: number,
  startDate: number
});

const studentParsed = shape({
  ...studentShape,
  dob: date,
  startDate: date
});

const studentParsedWithoutInstitution = shape({
  ...studentShape,
  institution: undefined,
  dob: date,
  startDate: date
});

export default {
  course,
  courseAlgolia,
  session,
  sessionAlgolia,
  section,
  sectionAlgolia,
  sessionCourseAlgolia,
  consortium,
  institution,
  institutionNoData,
  institutionData,
  institutionAlgolia,
  institutionGradingScale,
  student,
  studentParsed,
  prerequisite,
  courseSchedule
};
