import type { FC} from 'react';
import React, { useCallback, useMemo } from 'react';

import type { InstitutionAutocompleteProps } from '../../InstitutionAutocomplete';
import { InstitutionAutocomplete } from '../../InstitutionAutocomplete';

import type { BaseFilterProps, FilterDefinition } from '../context';
import { useRegisterFilter } from '../context';
import type { Id, Option, Options } from '@acadeum/types';
import { isObject } from '@acadeum/helpers';

export interface InstitutionFilterDefinition<V extends Id> extends FilterDefinition<V> {
  options?: Option<V | null> | Options<V | null>
}

export interface InstitutionFilterProps extends BaseFilterProps, Omit<InstitutionAutocompleteProps, 'value' | 'onChange'> {
}

export const InstitutionFilter: FC<InstitutionFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  type,
  multiple,
  ...rest
}) => {
  const getFilterLabel = useCallback( (value, item) => {
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, item);
    }

    if (Array.isArray(item.options)) {
      const selectedItem = item.options.find(_ => _.value === value);
      if (selectedItem) {
        return selectedItem.label;
      }
    } else if (isObject(item.options)) {
      return item.options.label;
    }
    return item.label;
  }, [propsGetFilterLabel]);

  const filterDefinition = useMemo<InstitutionFilterDefinition<Id>>(() => ({
    name,
    label,
    getFilterLabel
  }), [name, label, getFilterLabel]);

  const registerOptions = useMemo(() => {
    return {
      propsOnChange,
      filter: filterDefinition
    };
  }, [propsOnChange, filterDefinition]);

  const {
    value,
    onChange: onChange_
  } = useRegisterFilter(registerOptions);

  const onChange = (value, optionOrOptions) => {
    onChange_(value, {
      ...filterDefinition,
      options: optionOrOptions
    });
  };

  return (
    <InstitutionAutocomplete
      {...rest}
      isFilter
      isFormField={false}
      multiple={multiple}
      type={type}
      label={label}
      value={value}
      onChange={onChange}
    />
  );
};
