import React from 'react';
import type { FC } from 'react';
import classNames from 'classnames';

import Link from '../Link';

import PencilIcon from '../../../assets/images/icons/pencil.svg';
// import InfoIcon from '../../../assets/images/icons/info.svg';
import CloseIcon from '../../../assets/images/icons/close.svg';

import { CircleInfoIcon as InfoIcon } from '@acadeum/icons';
import type { IconSource } from '@acadeum/types';

import './IconButton.sass';

type IconType = 'edit' | 'info' | 'remove';

interface IconButtonProps {
  inline?: boolean;
  link?: string;
  onClick: () => void;
  icon: IconSource | IconType;
  title: string;
  className?: string;
}

const IconButton: FC<IconButtonProps> = ({
  inline,
  icon: Icon,
  link,
  onClick,
  title,
  className,
  ...rest
}) => {
  if (typeof Icon === 'string') {
    Icon = getIconByName(Icon);
  }
  const icon = <Icon className="icon-button__icon"/>;
  className = classNames(className, 'icon-button', {
    'icon-button--inline': inline
  });
  if (link) {
    return (
      <Link
        {...rest}
        to={link}
        title={title}
        aria-label={title}
        className={classNames('icon-button--link', className)}>
        {icon}
      </Link>
    );
  }
  return (
    <button
      {...rest}
      type="button"
      onClick={onClick}
      title={title}
      className={classNames('rrui__button-reset', className)}>
      {icon}
    </button>
  );
};

export default IconButton;

function getIconByName(iconName: IconType) {
  switch (iconName) {
    case 'edit':
      return PencilIcon;
    case 'info':
      return InfoIcon;
    case 'remove':
      return CloseIcon;
  }
}
