import React from 'react';
import classNames from 'classnames';

import { XMarkIcon } from '@acadeum/icons';

import { Icon } from '../../Icon';

import styles from './Badge.module.scss';

export interface BadgeProps {
  label: string;
  onRemove?: React.MouseEventHandler<HTMLElement>;
}

export const Badge: React.FC<BadgeProps> = ({
  label,
  onRemove
}) => {
  return (
    <div className={classNames(styles.root, {
      [styles.withRemoveButton]: onRemove
    })}
    >
      <span className={styles.text}> 
        {label}
      </span>
      {onRemove && (
        <div
          role="button"
          aria-label={`Remove ${label}`}
          onClick={onRemove}
          className={styles.removeButton}
        >
          <Icon icon={XMarkIcon}/>
        </div>
      )}
    </div>
  );
};
