import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import formatName from 'common-lib/lib/formatName';
import ENROLL_REASONS from 'common-lib/constants/createCourseEnrollmentReasons.json';

import {
  Col,
  CloseButton,
  ContentSection,
  FormField,
  Row,
  Text
} from '@acadeum/ui';

import { useTranslate } from '@acadeum/translate';

import './StudentOverviewCard.sass';

const StudentOverviewCard = ({ student, counter, type, onRemove }) => {
  const { enrollmentState } = useSelector(state => state.enroll);
  const { advisorRecommendationCreationState } = useSelector(state => state.advisorRecommendations);
  const reasonData = enrollmentState?.reasons?.find(reason => reason.studentId === student.id);
  const recommendationNotes = advisorRecommendationCreationState?.notes?.find(note => note.knownStudentId === student.id);


  const [showReasonNotesField, setShowReasonNotesField] = useState(reasonData?.reason === 'OTHER');

  const t = useTranslate('StudentOverviewCard');

  const enrollmentReasonPrepare = () => {
    const reasons = ENROLL_REASONS.map(reason => (
      {
        value: reason,
        label: reason[0] + reason.substring(1).split('_').join(' ').toLocaleLowerCase()
      }
    ));

    reasons.push({
      value: 'OTHER',
      label: 'Other'
    });

    return reasons;
  };

  const onReasonChange = (e) => {
    if (e.target.value === 'OTHER') {
      setShowReasonNotesField(true);
    } else {
      setShowReasonNotesField(false);
    }
  };

  return (
    <>
      <ContentSection className="StudentOverviewCard" padding="md">
        <div className="StudentOverviewCard__title">
          <span>
            {formatName(student)}
          </span>
          <CloseButton
            className="StudentOverviewCard__closeButton"
            onClick={() => onRemove(student)}
          />
        </div>
        <div className="StudentOverviewCard__content">
          <Row>
            <Col md={5}>
              {t('studentInfo')}
            </Col>
            <Col md={7}>
              <Text truncate>
                {student.email}
              </Text>
              {student.hiStudentId && (
                <Text color="grey" variant="bodyMd">
                  {t('id', { id: student.hiStudentId })}
                </Text>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={5}>
              {type === 'ENROLLMENT'
                ? t('enrollmentReason', {
                  optional: <span className="StudentOverviewCard__optional">({t('optional')})</span>
                })
                : t('recommendation')
              }
            </Col>
            <Col style={{ display: 'flex', alightItems: 'center' }} md={7}>
              {type === 'ENROLLMENT' ? (
                <>
                  <FormField
                    style={{marginBottom: '1rem'}}
                    type="select"
                    name={`reasons.${counter}`}
                    defaultValue={reasonData?.reason}
                    options={enrollmentReasonPrepare()}
                    onChange={e => onReasonChange(e)}
                  />
                  {showReasonNotesField && (
                    <FormField
                      noMargin
                      multiline
                      name={`reasonNotes.${counter}`}
                      placeholder={t('enrollReason.placeholder')}
                      defaultValue={reasonData?.reasonNotes}
                    />
                  )}
                </>
              ) : (
                <FormField
                  noMargin
                  multiline
                  required
                  name={`notes.${counter}`}
                  label={t('notes.label')}
                  placeholder={t('notes.placeholder')}
                  defaultValue={recommendationNotes?.notes}
                />
              )}
            </Col>
          </Row>
        </div>
      </ContentSection>
    </>
  );
};

StudentOverviewCard.propTypes = {
  student: PropTypes.any.isRequired,
  counter: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['ENROLLMENT', 'RECOMMENDATION']).isRequired,
  onRemove: PropTypes.func.isRequired
};

export default StudentOverviewCard;
