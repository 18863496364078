// This file was written in analogy with the out-of-the-box `connectXxx` ones from `react-instantsearch` library:
// https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/connectors/connectRange.js
// https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/connectors/connectToggleRefinement.js

// import omit from 'lodash/omit';

import { createConnector } from 'react-instantsearch-core';
// import createConnector from './react-instantsearch-core/core/createConnector.js';

export default createConnector({
  displayName: 'InstantSearchExcludePastConnector',

  getProvidedProps(props, searchState) {
    return {
      currentRefinement: getCurrentRefinement(searchState, props)
    };
  },

  refine(props, searchState, nextRefinement) {
    const newSearchState = {
      ...searchState,
      excludePast: {
        [props.attribute]: isDefault(nextRefinement, props) ? undefined : nextRefinement
      }
    };
    // Reset the current page number.
    // https://github.com/Acadeum/Students-site/issues/711
    delete newSearchState.page;
    return newSearchState;
  },

  cleanUp(props, searchState) {
    if (searchState.excludePast === undefined) {
      return searchState;
    }
    if (searchState.excludePast[props.attribute] === undefined) {
      return searchState;
    }
    // return omit(searchState, 'excludePast.' + props.attribute);
    return {
      ...searchState,
      excludePast: {
        ...searchState.excludePast,
        [props.attribute]: undefined
      }
    };
  },

  getSearchParameters(searchParameters, props, searchState) {
    if (getCurrentRefinement(searchState, props)) {
      return searchParameters.addNumericRefinement(
        props.attribute,
        '>=',
        getCurrentTimestampRounded(props) - (props.timeOffset || 0)
      );
    }
    return searchParameters;
  },

  getMetadata(props, searchState) {
    const items = [];

    const isNonDefault = searchState.excludePast && !isDefault(searchState.excludePast[props.attribute], props);

    if (isNonDefault) {
      items.push({
        label: props.clearLabel || props.label,
        attribute: props.attribute,
        value: (nextState) => ({
          ...nextState,
          excludePast: {
            ...nextState.excludePast,
            [props.attribute]: undefined
          }
        }),
        currentRefinement: getCurrentRefinement(searchState, props)
      });
    }

    return {
      id: props.attribute,
      index: getIndex(this.context),
      items
    };
  }
});

function isDefault(value, props) {
  if (value === undefined) {
    return true;
  }
  const defaultRefinement = props.defaultRefinement === undefined ? false : props.defaultRefinement;
  return value === defaultRefinement;
}

function getIndex(context) {
  return context && context.multiIndexContext
    ? context.multiIndexContext.targetedIndex
    : context.ais.mainTargetedIndex;
}

function getCurrentRefinement(searchState, props) {
  const excludePast = searchState.excludePast;
  return !excludePast || excludePast[props.attribute] === undefined ? props.defaultRefinement : excludePast[props.attribute];
}

function getCurrentTimestampRounded(props) {
  // `step` property is used in `connectInstantSearchExcludePast()`
  // in order to "round" the current timestamp (`Date.now()`) when getting
  // filter value. That works around an issue when it sends a lot of
  // HTTP queries to Algolia because on each re-render the filter value
  // (which is `Date.now()`) is a bit different than the previous one.
  const step = props.step || 'minute';
  const stepDuration = getStepDuration(step);
  return Math.floor(Date.now() / stepDuration) * stepDuration;
}

function getStepDuration(step) {
  switch (step) {
    case 'minute':
      return 60 * 1000;
    default:
      throw new Error(`Unsupported <InstantSearchExcludePast/> step: "${step}"`);
  }
}
