import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { formatCurrency } from '../../../utils/format';

import type { PriceInputProps } from '../../PriceInput';
import { PriceInput } from '../../PriceInput';

import type { BaseFilterProps, FilterDefinition } from '../context';
import { useRegisterFilter } from '../context';

interface PriceFilterValue {
  from?: number;
  to?: number;
}

type PriceFilterDefinition = FilterDefinition<PriceFilterValue>

export interface PriceFilterProps extends Omit<BaseFilterProps, 'getFilterLabel'>, Omit<PriceInputProps, 'value' | 'onChange'> {
  onChange?: (value: PriceFilterValue | null) => PriceFilterValue;
  getFilterLabel?: (value: PriceFilterValue, defaultLabel: string) => string;
}

export const PriceFilter: FC<PriceFilterProps> = ({
  name,
  label,
  getFilterLabel: propsGetFilterLabel,
  onChange: propsOnChange,
  ...rest
}) => {
  const getFilterLabel = useCallback( (value: PriceFilterValue) => {
    const defaultLabel = getDefaultFilterLabel(value);
    if (propsGetFilterLabel) {
      return propsGetFilterLabel(value, defaultLabel);
    }
    return defaultLabel;
  }, [propsGetFilterLabel]);

  const filterDefinition = useMemo<PriceFilterDefinition>(() => ({
    name,
    label,
    getFilterLabel
  }), [name, label, getFilterLabel]);

  const {
    value,
    onChange: onChange_
  } = useRegisterFilter<PriceFilterValue, PriceFilterDefinition>({ propsOnChange, filter: filterDefinition });

  const onChange = (value: PriceFilterValue) => {
    const isEmpty = typeof value.from !== 'number' && typeof value.to !== 'number';
    const newValue = isEmpty ? null : value;
    onChange_(newValue, filterDefinition);
  };

  return (
    <PriceInput
      {...rest}
      label={label}
      onChange={onChange}
      value={{
        from: value ? value?.from : undefined,
        to: value ? value?.to : undefined
      }}
    />
  );
};

function getDefaultFilterLabel(value: PriceFilterValue) {
  if (typeof value.from === 'number' && typeof value.to === 'number') {
    return `${formatCurrency(value.from)} - ${formatCurrency(value.to)}`;
  }

  if (typeof value.from === 'number') {
    return `${formatCurrency(value.from)} - ∞`;
  }

  if (typeof value.to === 'number') {
    return `∞ - ${formatCurrency(value.to)}`;
  }

  return '∞ - ∞';
}
