import config from 'config';

import algoliasearch from 'algoliasearch';

// Algolia updates take effect within 10-20 seconds or so.
// Could be implemented as a "job" but that would be too much "moving parts".
export const ALGOLIA_UPDATE_PENDING_NOTE = ' Changes will take place in approximately 20 seconds. Please refresh your browser after that to view the changes.';

export function getAlgoliaIndex(
  indexKey,
  { stagePrefix }: { stagePrefix?: boolean } = {}
) {
  return getAlgoliaIndex_(getAlgoliaIndexName(indexKey, { stagePrefix }), {
    appId: config.algolia.appID,
    apiKey: config.algolia.apiKey
  });
}

function getAlgoliaIndexName (
  indexKey,
  { stagePrefix }: { stagePrefix?: boolean } = {}
) {
  if (stagePrefix === false) {
    return indexKey;
  }
  return (config.algolia.prefix || '') + indexKey;
}

function getAlgoliaIndex_(indexName, {
  appId,
  apiKey
}) {
  return IndexCache.get(
    appId,
    indexName,
    () => {
      // TS2349: This expression is not callable. - It's expected error because versions of `algoliasearch` a old one. And there no type definitions.
      // @ts-expect-error
      const index = algoliasearch(appId, apiKey).initIndex(indexName);
      return new AlgoliaIndex(index);
    }
  );
}

// Algolia index cache.
// Only works on client side.
const IndexCache = {
  cache: {},
  get(appId, indexName, create) {
    if (typeof window === 'undefined') {
      return create();
    }
    if (!this.cache[appId]) {
      this.cache[appId] = {};
    }
    if (!this.cache[appId][indexName]) {
      this.cache[appId][indexName] = create();
    }
    return this.cache[appId][indexName];
  }
};

// https://www.algolia.com/doc/api-reference/api-parameters/hitsPerPage/
// "1000 is the maximum".
const MAX_HITS_PER_PAGE = 1000;

class AlgoliaIndex {
  private index;

  constructor(index) {
    this.index = index;
  }

  async get(id: string) {
    try {
      return await this.index.getObject(id);
    } catch (error) {
      // { name: 'ApiError', message: 'ObjectID does not exist', status: 404 }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.status === 404) {
        return;
      }
      throw error;
    }
  }

  async findAll({ query, where, limit }: { query: string; where?: NonNullable<unknown>; limit?: number }) {
    validateLimit(limit);
    const { hits } = await this.index.search(query, {
      filters: getFilters(where),
      hitsPerPage: limit
    });
    // Currently, `limit` parameter is required.
    // If it was not required and was assigned some "default" value,
    // then it would also have to check whether that limit is exceeded.
    // if (!limit && nbHits > MAX_HITS_PER_PAGE) {
    //   console.error(`Algolia ".findAll()" index method returned ${MAX_HITS_PER_PAGE} results, but the total results
    // count is larger: ${nbHits}.`); }
    return hits;
  }

  async findOne({ query, where }: { query: string; where?: NonNullable<unknown> }) {
    const { hits } = await this.index.search(query, {
      filters: getFilters(where),
      hitsPerPage: 1
    });
    return hits[0];
  }

  async count({ query, where }: { query: string; where?: NonNullable<unknown> }) {
    const { nbHits } = await this.index.search(query, {
      filters: getFilters(where),
      hitsPerPage: 1
    });
    return nbHits;
  }
}

function getFilters(where) {
  if (!where) {
    return;
  }
  const properties = Object.keys(where);
  if (properties.length === 0) {
    return;
  }
  return properties
    .map(property => `${property}:${where[property]}`)
    .join(' AND ');
}

function validateLimit(limit) {
  if (!limit) {
    throw new Error('Algolia results count limit parameter is required');
  }
  if (limit > MAX_HITS_PER_PAGE) {
    throw new Error(`Algolia results count limit can't be over ${MAX_HITS_PER_PAGE}: ${limit}`);
  }
}
