import { array } from './shared';

import { contactSchema } from './contact';
import { coursePricingSchema } from './coursePricing';

const consortiumPrimaryContactSchema = contactSchema.required();
export function validateConsortiumPrimaryContact(value) {
  const { error } = consortiumPrimaryContactSchema.validate(value, { convert: false });

  if (error) {
    console.error(error);
    return `Invalid "Primary Contact": ${error.message}`;
  }
}

const consortiumCourseEnrollmentPricing = array().items(coursePricingSchema);
export function validateConsortiumCourseEnrollmentPricing(value) {
  // They say using `.nullable()` on arrays is buggy.
  // https://github.com/jquense/yup/issues/1497
  // So validating an empty value case here manually instead.
  if (value === null) {
    return;
  }

  const { error } = consortiumCourseEnrollmentPricing.validate(value, { convert: false });
  if (error) {
    console.error(error);
    return `Invalid "Course Enrollment Pricing": ${error.message}`;
  }

  // Each pricing variant within a consortium should be limited to a set of Teaching Institutions.
  for (const variant of value) {
    if (!variant.teachingInstitutionId) {
      const message = 'Each pricing variant must have an `teachingInstitutionId` property which limits the pricing to only the courses of that (or those) Teaching Institution(s)';
      return `Invalid "Course Enrollment Pricing": ${message}`;
    }
  }
}
