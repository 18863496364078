import UTC_DATE_TIME_OFFSET from '../constants/utcDateTimeOffset.js';
import END_OF_DAY_TIME_OFFSET from '../constants/endOfDayTimeOffset.js';

const DAY = 24 * 60 * 60 * 1000;

// Returns the lower border for a UTC+0 timestamp of a course's "Last Add Date"
// in order for students to be able to request enrollment in the course.
// If a course's "Last Add Date" is greater or equal to this timestamp,
// students can still request enrollment. Otherwise, the train has already left.
//
export default function getMinCourseSectionLastAddDateTimeWhenStillCanRequestEnrollmentInCourse() {
  // `lastAddDate` has the time of `00:00` in `UTC+0` time zone
  // but is meant to be `00:00` in Pacific Time Zone.
  // Hence, `UTC_DATE_TIME_OFFSET` should be added to it.
  //
  // const lastDateToEnroll = courseSection.lastAddDate + END_OF_DAY_TIME_OFFSET + UTC_DATE_TIME_OFFSET;
  // const lastDateToRequestEnrollment = lastDateToEnroll;
  //
  // Can request enrollment when: lastDateToRequestEnrollment >= Date.now();
  //
  // =>
  // courseSection.lastAddDate + END_OF_DAY_TIME_OFFSET + UTC_DATE_TIME_OFFSET >= Date.now()
  //
  // =>
  // courseSection.lastAddDate >= Date.now() - (END_OF_DAY_TIME_OFFSET + UTC_DATE_TIME_OFFSET)
  //
  return Date.now() - (END_OF_DAY_TIME_OFFSET + UTC_DATE_TIME_OFFSET);
}
