import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  ConfirmActionModal,
  ContentSection,
  Form,
  Separator,
  StickyFormFooter,
  Text
} from '@acadeum/ui';

import { useTranslate } from '@acadeum/translate';

import CourseOverviewSection from '../CourseOverviewSection';

import SectionCard from '../SectionCard';
import StudentOverviewCard from '../../../../components/StudentOverviewCard';

import { formatName } from '../../../../helpers/format';

import actions from '../../../../actions';

import './Summary.sass';

const {
  saveAdvisorRecommendationCreationState,
  resetAdvisorRecommendationCreationState,
  createAdvisorRecommendation,
  notifyError,
  goto
} = actions;

export default function Summary({ navigation, sectionId, setShowSuccessModal }) {
  const { course } = useSelector(state => state.courses);
  const { advisorRecommendationCreationState } = useSelector(state => state.advisorRecommendations);

  const [showRemoveStudentConfirmationModal, setShowRemoveStudentConfirmationModal] = useState();
  const [studentToRemove, setStudentToRemove] = useState();

  const t = useTranslate('CreateAdvisorRecommendationSummary');

  const onBackAction = async (notes) => {
    saveAdvisorRecommendationCreationState({ notes });
    navigation.previous();
  };

  const onSubmitAction = async (notes) => {
    const {
      courseSubstituteId,
      sectionIds,
      students
    } = advisorRecommendationCreationState;
    await createAdvisorRecommendation({
      sectionIds,
      courseSubstituteId,
      knownStudentIds: students.map(_ => _.id),
      notes
    });
    setShowSuccessModal(true);
    resetAdvisorRecommendationCreationState();
  };

  const onSubmit = async (values) => {
    const { formAction, ...restValues } = values;
    const notes = matchNotes(restValues);

    switch (formAction) {
      case 'back':
        await onBackAction(notes);
        break;
      case 'submit':
        await onSubmitAction(notes);
        break;
    }
  };

  const matchNotes = (values) => {
    const { notes } = values;
    const studentIds = advisorRecommendationCreationState.students.map(_ => _.id);

    const matchedNotes = studentIds.reduce((result, id, index) => {
      if (notes && notes[index]) {
        result.push({
          knownStudentId: id,
          notes: notes[index]
        });
      }

      return result;
    }, []);

    return matchedNotes;
  };

  const onRemoveStudent = (student) => {
    if (advisorRecommendationCreationState.students.length === 1) {
      return notifyError(t('oneStudentShouldLeft'));
    }
    setShowRemoveStudentConfirmationModal(true);
    setStudentToRemove(student);
  };

  const onConfirmRemoveStudent = () => {
    saveAdvisorRecommendationCreationState({
      ...advisorRecommendationCreationState,
      students: students.filter(student => student.id !== studentToRemove.id)
    });
    setShowRemoveStudentConfirmationModal(false);
  };

  if (!course || !advisorRecommendationCreationState) {
    return null;
  }

  const onCancel = () => {
    const url = sectionId ? `/sections/${sectionId}` : `/courses/${course.id}`;

    resetAdvisorRecommendationCreationState();
    goto(url);
  };

  const { sectionIds, students } = advisorRecommendationCreationState;

  const sections = [];
  for (const session of course.sessions) {
    for (const section of session.sections) {
      if (sectionIds.includes(section.id)) {
        sections.push({
          ...section,
          session: {
            ...session,
            course
          }
        });
      }
    }
  }

  return (
    <>
      <ContentSection border={false} padding="none">
        <Form className="form" onSubmit={onSubmit}>
          <ContentSection>
            <ContentSection
              border={false}
              padding="none"
              title={t('courseOverview')}
            >
              <CourseOverviewSection course={course}/>
            </ContentSection>
            <Separator />
            <ContentSection
              border={false}
              padding="none"
              title={t('sectionsOverview', {
                count: sections.length
              })}
            >
              <Text>
                {t('sectionOverviewDescription')}
              </Text>
              {sections.map((section, index) => (
                <SectionCard key={index} section={section}/>
              ))}
            </ContentSection>
            <Separator />
            <ContentSection
              border={false}
              padding="none"
              title={t('studentsOverview')}
            >
              <Text>
                {t('studentsOverviewDescription')}
              </Text>
              {students.map((student, index) => (
                <StudentOverviewCard
                  key={index}
                  counter={index}
                  student={student}
                  type="RECOMMENDATION"
                  onRemove={onRemoveStudent}
                />
              ))}
            </ContentSection>
          </ContentSection>

          <StickyFormFooter
            onCancelProps={{
              onClick: onCancel
            }}
            onBackProps={{
              action: 'back',
              skipValidation: true
            }}
            submitProps={{
              action: 'submit',
              children: t('form.create')
            }}
          />
        </Form>

        {studentToRemove && (
          <ConfirmActionModal
            show={showRemoveStudentConfirmationModal}
            title={t('modal.title')}
            description={t('modal.description', {
              name: <span className="Summary__studentInfo">{formatName(studentToRemove)}</span>
            })}
            submitText={t('remove', { global: true })}
            onSubmit={onConfirmRemoveStudent}
            onHide={() => setShowRemoveStudentConfirmationModal(false)}
            onCancel={() => setShowRemoveStudentConfirmationModal(false)}
          />
        )}
      </ContentSection>
    </>
  );
}

Summary.propTypes = {
  navigation: PropTypes.shape({
    previous: PropTypes.func,
    next: PropTypes.func,
    go: PropTypes.func
  }).isRequired,
  sectionId: PropTypes.number,
  setShowSuccessModal: PropTypes.func.isRequired
};
